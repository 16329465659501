<template>
  <div class="font-button">
    <font-awesome-icon class="link-icon" :icon="icon" />
    <span>
      <strong>{{ title }}</strong>
    </span>
  </div>
</template>

<script>
export default {
  name: "FontButton",
  props: {
    title: String,
    icon: String
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.link-icon {
  font-size: 2.5em;
}
</style>
