<template>
  <div id="not-found-page">
    <h1 class="title">(404) Page Not Found</h1>
    <FontButton
      v-if="redirect"
      icon="arrow-left"
      title="Back"
      @click.native="goBack"
    />
    <FontButton
      v-if="redirect"
      icon="home"
      title="Home"
      @click.native="homePage"
    />
  </div>
</template>

<script>
import FontButton from "@/components/FontButton";

export default {
  name: "NotFound",
  components: {
    FontButton
  },
  props: {
    redirect: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    homePage: function() {
      this.$router.push({ name: "home" });
    },
    goBack: function() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#not-found-page {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  padding: 30px;
}

.font-button {
  display: inline-block;
  width: 50px;
  cursor: pointer;
  margin: 10px;

  & > .link-icon {
    font-size: 3em;
    font-weight: bold;
  }
}
</style>
